import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  useForm,
} from "react-hook-form";
import { AdminType } from "../../../types/admin";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_SETUPS, GET_INTERMEDIARIES } from "../../../GraphQl/queries";
import { IntermediaryType } from "../../../types/intermediart";

export const CreateSubAgent = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("info");

  const { data: setups, loading } = useQuery(GET_ALL_SETUPS);

  const { data, loading: intermediaryLoading } = useQuery(GET_INTERMEDIARIES);

  const {
    register: adminRegister,
    handleSubmit: adminSubmit,
    setValue,
    formState: { errors: adminError, isSubmitting: isadminSubmitting },
  } = useForm<AdminType>();

  const onSubmit: SubmitHandler<AdminType> = (data) => {};

  const accountTyes = [
    {
      label: "Personal Information",
      value: "info",
    },
    {
      label: "Payment account",
      value: "bank",
    },
    {
      label: "Commission",
      value: "commission",
    },
  ];

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const navigateBack = () => {
    history.push("/dashboard/subagent");
    window.location.reload();
  };

  return (
    <>
      <CustomHeader title="Create sub agent" />

      <Tooltip content="Sub agents">
        <IconButton color="white" onClick={navigateBack}>
          <BiArrowBack size={20} />
        </IconButton>
      </Tooltip>

      <div className="my-5 bg-white pb-5 rounded-xl">
        <Tabs id="custom-animation" value={activeTab}>
          <TabsHeader defaultValue={activeTab} className="bg-brand-primary">
            {accountTyes.map(({ value, label }) => (
              <Tab
                className="font-semibold text-sm capitalize"
                key={value}
                value={value}
                onClick={() => handleTabChange(value)}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <div className="py-5">
            <TabsBody
              defaultValue={activeTab}
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
            >
              <TabPanel key={activeTab} value={activeTab}>
                {activeTab === "info" ? (
                  <AdminForm
                    onSubmit={onSubmit}
                    register={adminRegister}
                    errors={adminError}
                    intermediaries={data?.intemediary}
                    handleSubmit={adminSubmit}
                    loading={intermediaryLoading}
                  />
                ) : activeTab === "bank" ? (
                  <BankForm
                    onSubmit={onSubmit}
                    register={adminRegister}
                    errors={adminError}
                    handleSubmit={adminSubmit}
                    banks={setups?.banks}
                  />
                ) : (
                  <>
                    <div>
                      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
                        <div className="w-1/4">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-bold text-sm"
                          >
                            Comission rate
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            error={!!adminError.comission_rate}
                            size="lg"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...adminRegister("comission_rate", {
                              required: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
            </TabsBody>
          </div>
        </Tabs>

        <div className="flex items-end justify-end">
          <Button className="bg-brand-primary mx-5">
            {isadminSubmitting ? <Spinner className="w-5 h-5" /> : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

const AdminForm = ({
  handleSubmit,
  errors,
  onSubmit,
  register,
  intermediaries,
  loading,
}: {
  handleSubmit: UseFormHandleSubmit<AdminType, undefined>;
  register: UseFormRegister<AdminType>;
  errors: FieldErrors<AdminType>;
  onSubmit: SubmitHandler<AdminType>;
  intermediaries: IntermediaryType[];
  loading: boolean;
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Intermediary
          </Typography>
          <select
            placeholder="Choose intermediary"
            id="intermediaries"
            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected>
              Choose intermediary {loading ? "Loading..." : ""}
            </option>
            {intermediaries?.map((item) => (
              <option label={item?.name} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Name
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.name}
            size="lg"
            placeholder="Name"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            // {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Email
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.email}
            size="lg"
            placeholder="Email"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("email", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Phone Number
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.phoneNumber}
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("phoneNumber", { required: true })}
          />
        </div>
      </div>
    </form>
  );
};

const BankForm = ({
  handleSubmit,
  errors,
  onSubmit,
  register,
  banks,
}: {
  handleSubmit: UseFormHandleSubmit<AdminType, undefined>;
  register: UseFormRegister<AdminType>;
  errors: FieldErrors<AdminType>;
  onSubmit: SubmitHandler<AdminType>;
  banks: {
    id?: string;
    name: string;
    code: string;
  }[];
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Bank name
          </Typography>

          <select
            id="banks"
            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("name", { required: true })}
          >
            <option selected>Choose bank</option>
            {banks?.map((item) => (
              <option label={item?.name} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Email
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.email}
            size="lg"
            placeholder="Email"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("email", { required: true })}
          />
        </div>
      </div>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Account number
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.phoneNumber}
            size="lg"
            type="number"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("phoneNumber", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Phone Number
          </Typography>
          <Input
            crossOrigin="anonymous"
            error={!!errors.phoneNumber}
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("phoneNumber", { required: true })}
          />
        </div>
      </div>
    </form>
  );
};
