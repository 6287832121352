import {
  UsersView,
  CreateNotification,
  Customers,
  DashboardOverview,
  CreateIntermediaries,
  Payments,
  Policies,
  ReportView,
  RiskClass,
  RolesAndPermissions,
  Settings,
  CreateSubAgent,
  Intermediary,
  BanksPage,
  DiscountPage,
  VehiclePage,
  CurrenyPage,
  BodyTypePage,
  CreateAdmin,
  SubAgents,
  CountryPage,
  Company,
  CreateComapny,
  CreateStaff,
  Staff,
  Premium,
  CommissionsView,
  FeeView,
  StaffsView,
  InsuranceCompanyView,
  ClientView,
  QuotationView,
  NewPolicyView,
  RenewalView,
  SetupLocationView,
  SetupBranchView,
  ManageDistrict,
  SetupCLientTypeView,
  ManageTown,
  SetupWorkGroupView,
  PremiumRateView,
  LevieView,
  ManageLevieView,
  ManageComissionRate,
  SetupLoading,
  ManageLoadingRate,
  ManageDiscountRate,
  SetupDiscount,
  ManageSubclass,
  BusinessClassView,
  PerilView,
  ExclusionView,
  CoverTypeView,
  ManagePerilAndExlusion,
  AdminstratorDashboardOverview,
  TaxView,
  ManageTaxes,
  RiskClassView,
  ManageRiskRate,
  RequisitionView,
  Agents,
  ManageAgent,
  CompanyStaff,
  ManageStaff,
  SubscriptionView,
  SubscriptionsPage,
  PremiumPaymentOrder,
  SubscriptionPaymentOrder,
  ExternalUsers,
  ManageAdminView,
  Index,
  Template,
  VehiclePrefix,
  VehicleMake,
  VehicleModel,
  VehicleColor,
  ConstructionType,
  UseOfProperty,
} from "./pages";
import SetupCommission from "./pages/dashboard/commissions";

export const AdminprivateRoutes = [
  // {
  //   path: "/",
  //   component: DashboardOverview,
  //   name: "dashboard",
  //   exact: true,
  //   layout: "/dashboard",
  // },
  {
    path: "/",
    component: AdminstratorDashboardOverview,
    name: "dashboard",
    exact: true,
    layout: "/dashboard",
  },
  {
    path: "/intermediaries",
    exact: false,
    component: UsersView,
    name: "intermediaries",
    layout: "/dashboard",
  },
  {
    path: "/insurance-companies",
    exact: false,
    component: InsuranceCompanyView,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/subscriptions",
    exact: false,
    component: SubscriptionsPage,
    name: "subscriptions",
    layout: "/dashboard",
  },
  {
    path: "/client",
    exact: false,
    component: ClientView,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/policy",
    exact: false,
    component: Policies,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/quotations",
    exact: false,
    component: QuotationView,
    name: "quotation",
    layout: "/dashboard",
  },
  {
    path: "/new-policy",
    exact: false,
    component: NewPolicyView,
    name: "new-policy",
    layout: "/dashboard",
  },
  {
    path: "/renewals",
    exact: false,
    component: RenewalView,
    name: "renewals",
    layout: "/dashboard",
  },
  {
    path: "/create-company",
    exact: false,
    component: CreateComapny,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/staffs",
    exact: false,
    component: StaffsView,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/create-staffs",
    exact: false,
    component: CreateStaff,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/create-notification",
    exact: false,
    component: CreateNotification,
    name: "create-notification",
    layout: "/dashboard",
  },
  {
    path: "/reports",
    exact: false,
    component: ReportView,
    name: "reports",
    layout: "/dashboard",
  },
  {
    path: "/settings",
    exact: false,
    component: Settings,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/messages/index",
    exact: false,
    component: Index,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/messages/template",
    exact: false,
    component: Template,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/customers",
    exact: false,
    component: Customers,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/premium",
    exact: false,
    component: Premium,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/commissions",
    exact: false,
    component: CommissionsView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/fees",
    exact: false,
    component: FeeView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-intermediary",
    exact: false,
    component: CreateIntermediaries,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-staff",
    exact: false,
    component: CreateStaff,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/create-users",
    exact: false,
    component: CreateAdmin,
    name: "users",
    layout: "/dashboard",
  },
  {
    path: "/setup/staff",
    exact: false,
    component: Staff,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/setup/intermediaries",
    exact: false,
    component: Intermediary,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/insurance-companies",
    exact: false,
    component: Company,
    name: "company",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-insurance-company",
    exact: false,
    component: CreateComapny,
    name: "create-company",
    layout: "/dashboard",
  },
  {
    path: "/setup/risk-class",
    exact: false,
    component: RiskClass,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/banks",
    exact: false,
    component: BanksPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/riskclass",
    exact: false,
    component: RiskClassView,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-riskclass",
    exact: false,
    component: ManageRiskRate,
    name: "settings",
    layout: "/dashboard",
  },
  // {
  //   path: "/setup/discounts",
  //   exact: false,
  //   component: DiscountPage,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/levies",
    exact: false,
    component: LevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/commissions",
    exact: false,
    component: SetupCommission,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/loadings",
    exact: false,
    component: SetupLoading,
    name: "loadings",
    layout: "/dashboard",
  },
  {
    path: "/setup/discounts",
    exact: false,
    component: SetupDiscount,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-discounts",
    exact: false,
    component: ManageDiscountRate,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/business-class",
    exact: false,
    component: BusinessClassView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-subclass",
    exact: false,
    component: ManageSubclass,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/peril",
    exact: false,
    component: PerilView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/exclusion",
    exact: false,
    component: ExclusionView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/cover-types",
    exact: false,
    component: CoverTypeView,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-peril-exclusion",
    exact: false,
    component: ManagePerilAndExlusion,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-levies",
    exact: false,
    component: ManageLevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-loadings",
    exact: false,
    component: ManageLoadingRate,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-commissions",
    exact: false,
    component: ManageComissionRate,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/premium-rates",
    exact: false,
    component: PremiumRateView,
    name: "premium-rates",
    layout: "/dashboard",
  },
  {
    path: "/setup/vehicle",
    exact: false,
    component: VehiclePage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/countries",
    exact: false,
    component: CountryPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/location",
    exact: false,
    component: SetupLocationView,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/Vechicle_Make_Model",
    exact: false,
    component: VehicleMake,
    name: "Vechicle_Make_Model",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_model/:id",
    exact: false,
    component:   VehicleModel,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/workgroups-divisions",
    exact: false,
    component: SetupWorkGroupView,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_district/:id",
    exact: false,
    component: ManageDistrict,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_town/:id",
    exact: false,
    component: ManageTown,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_types",
    exact: false,
    component: SetupCLientTypeView,
    name: "client_types",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_registration",
    exact: false,
    component: Company,
    name: "client_registration",
    layout: "/dashboard",
  },
  {
    path: "/setup/branches",
    exact: false,
    component: SetupBranchView,
    name: "branches",
    layout: "/dashboard",
  },
  {
    path: "/setup/roles",
    exact: false,
    component: RolesAndPermissions,
    name: "branches",
    layout: "/dashboard",
  },
  {
    path: "/mange-admins",
    exact: false,
    component: ManageAdminView,
    name: "mange-admins",
    layout: "/dashboard",
  },
  {
    path: "/setup/subscriptions",
    exact: false,
    component: SubscriptionView,
    name: "subscriptions",
    layout: "/dashboard",
  },
  {
    path: "/setup/body-types",
    exact: false,
    component: BodyTypePage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/vehicle-prefix",
    exact: false,
    component: VehiclePrefix,
    name: "settings",
    layout: "/dashboard",
  },

  {
    path: "/policies",
    exact: false,
    component: Policies,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/premium-payment-order",
    exact: false,
    component: PremiumPaymentOrder,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/external-users",
    exact: false,
    component: ExternalUsers,
    name: "ExternalUsers",
    layout: "/dashboard",
  },
  {
    path: "/subscription-payment-order",
    exact: false,
    component: SubscriptionPaymentOrder,
    name: "settings",
    layout: "/dashboard",
  },
  // {
  //   path: "/payments",
  //   exact: false,
  //   component: Payments,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/taxes",
    exact: false,
    component: TaxView,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-tax",
    exact: false,
    component: ManageTaxes,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/create-subagent",
    exact: false,
    component: CreateSubAgent,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/subagent",
    exact: false,
    component: SubAgents,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/roles",
    exact: false,
    component: RolesAndPermissions,
    name: "roles",
    layout: "/dashboard",
  },
  {
    path: "/setup/Vechicle_Color",
    exact: false,
    component: VehicleColor,
    name: "Vechicle_Color",
    layout: "/dashboard",
  },
  {
    path: "/setup/constructionType",
    exact: false,
    component: ConstructionType,
    name: "Construction_type",
    layout: "/dashboard",
  },
  {
    path: "/setup/useOfProperty",
    exact: false,
    component:UseOfProperty,
    name: "useOfProperty",
    layout: "/dashboard",
  },
];

export const CompanyPrivateRoutes = [
  {
    path: "/",
    component: DashboardOverview,
    name: "dashboard",
    exact: true,
    layout: "/dashboard",
  },
  {
    path: "/intermediaries",
    exact: false,
    component: UsersView,
    name: "intermediaries",
    layout: "/dashboard",
  },

  {
    path: "/insurance-companies",
    exact: false,
    component: InsuranceCompanyView,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/client",
    exact: false,
    component: ClientView,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/policy",
    exact: false,
    component: Policies,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/quotations",
    exact: false,
    component: QuotationView,
    name: "quotation",
    layout: "/dashboard",
  },
  {
    path: "/new-policy",
    exact: false,
    component: NewPolicyView,
    name: "new-policy",
    layout: "/dashboard",
  },
  {
    path: "/renewals",
    exact: false,
    component: RenewalView,
    name: "renewals",
    layout: "/dashboard",
  },
  {
    path: "/create-company",
    exact: false,
    component: CreateComapny,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/setup/staff",
    exact: false,
    component: CompanyStaff,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-staff",
    exact: false,
    component: ManageStaff,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/create-notification",
    exact: false,
    component: CreateNotification,
    name: "create-notification",
    layout: "/dashboard",
  },
  {
    path: "/reports",
    exact: false,
    component: ReportView,
    name: "reports",
    layout: "/dashboard",
  },
  {
    path: "/settings",
    exact: false,
    component: Settings,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/customers",
    exact: false,
    component: Customers,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/premium",
    exact: false,
    component: Premium,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/commissions",
    exact: false,
    component: CommissionsView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/fees",
    exact: false,
    component: FeeView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/requisition",
    exact: false,
    component: RequisitionView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-intermediary",
    exact: false,
    component: CreateIntermediaries,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/agent",
    exact: false,
    component: Agents,
    name: "agent",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-agent",
    exact: false,
    component: ManageAgent,
    name: "agent",
    layout: "/dashboard",
  },
  {
    path: "/setup/intermediaries",
    exact: false,
    component: Intermediary,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/insurance-companies",
    exact: false,
    component: Company,
    name: "company",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-insurance-company",
    exact: false,
    component: CreateComapny,
    name: "create-company",
    layout: "/dashboard",
  },
  {
    path: "/setup/risk-class",
    exact: false,
    component: RiskClass,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/banks",
    exact: false,
    component: BanksPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/riskclass",
    exact: false,
    component: RiskClassView,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-riskclass",
    exact: false,
    component: ManageRiskRate,
    name: "settings",
    layout: "/dashboard",
  },
  // {
  //   path: "/setup/discounts",
  //   exact: false,
  //   component: DiscountPage,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/levies",
    exact: false,
    component: LevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/commissions",
    exact: false,
    component: SetupCommission,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/loadings",
    exact: false,
    component: SetupLoading,
    name: "loadings",
    layout: "/dashboard",
  },
  {
    path: "/setup/discounts",
    exact: false,
    component: SetupDiscount,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-discounts",
    exact: false,
    component: ManageDiscountRate,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/business-class",
    exact: false,
    component: BusinessClassView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-subclass",
    exact: false,
    component: ManageSubclass,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/peril",
    exact: false,
    component: PerilView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/workgroups",
    exact: false,
    component: SetupWorkGroupView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/cover-types",
    exact: false,
    component: CoverTypeView,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-peril-exclusion",
    exact: false,
    component: ManagePerilAndExlusion,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-levies",
    exact: false,
    component: ManageLevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-loadings",
    exact: false,
    component: ManageLoadingRate,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-commissions",
    exact: false,
    component: ManageComissionRate,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/premium-rates",
    exact: false,
    component: PremiumRateView,
    name: "premium-rates",
    layout: "/dashboard",
  },
  {
    path: "/setup/vehicle",
    exact: false,
    component: VehiclePage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/countries",
    exact: false,
    component: CountryPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/location",
    exact: false,
    component: SetupLocationView,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_district/:id",
    exact: false,
    component: ManageDistrict,
    name: "location",
    layout: "/dashboard",
  }
  ,
  {
    path: "/setup/Vechicle_Make_Model",
    exact: false,
    component: VehicleMake,
    name: "Vechicle_Make_Model",
    layout: "/dashboard",
  },
  
  {
    path: "/setup/manage_model/:id",
    exact: false,
    component:   VehicleModel,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/Vechicle_Color",
    exact: false,
    component: VehicleColor,
    name: "Vechicle_Color",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_town/:id",
    exact: false,
    component: ManageTown,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_types",
    exact: false,
    component: SetupCLientTypeView,
    name: "client_types",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_registration",
    exact: false,
    component: Company,
    name: "client_registration",
    layout: "/dashboard",
  },

  {
    path: "/setup/branches",
    exact: false,
    component: SetupBranchView,
    name: "branches",
    layout: "/dashboard",
  },
  // {
  //   path: "/setup/currency",
  //   exact: false,
  //   component: CurrenyPage,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/body-types",
    exact: false,
    component: BodyTypePage,
    name: "settings",
    layout: "/dashboard",
  },

  {
    path: "/policies",
    exact: false,
    component: Policies,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/payments",
    exact: false,
    component: Payments,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/taxes",
    exact: false,
    component: TaxView,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-tax",
    exact: false,
    component: ManageTaxes,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/create-subagent",
    exact: false,
    component: CreateSubAgent,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/subagent",
    exact: false,
    component: SubAgents,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/roles",
    exact: false,
    component: RolesAndPermissions,
    name: "roles",
    layout: "/dashboard",
  },
  {
    path: "/setup/constructionType",
    exact: false,
    component: ConstructionType,
    name: "Construction_type",
    layout: "/dashboard",
  },
  {
    path: "/setup/useOfProperty",
    exact: false,
    component:UseOfProperty,
    name: "useOfProperty",
    layout: "/dashboard",
  },
];
