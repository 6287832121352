import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaUsers } from "react-icons/fa6";
import { id_types } from "../../../util";

const CompanyStaff = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  return (
    <>
      <CustomHeader title="Company registration" />

      <div className="">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className=" rounded-lg overflow-hidden w-full">
            <div className="bg-red-400 p-3">
              <Typography className="text-base text-white">
                User Details
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Role
                      </Typography>
                      <select className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        {/* <option label="MTN" value={"MTN"}>
                        MTN
                      </option> */}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number 1
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number 2
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        TIN
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        National id type
                      </Typography>
                      <select className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        {id_types?.map((item) => (
                          <option value={item?.code} label={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        ID number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Date of birth
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        type="date"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Guarantor Details
              </Typography>
              <hr className="my-1" />
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Guarantor name
                </Typography>
                <Input
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Address
                </Typography>
                <Input
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Phone number
                </Typography>
                <Input
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Guarantor ID
                </Typography>
                <Input
                  crossOrigin="anonymous"
                  size="md"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
            </div>
          </div>

          <div className=" rounded-lg overflow-hidden w-full">
            <div className="bg-red-400 p-3">
              <Typography className="text-base text-white">
                Branch and Division
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Branch
                      </Typography>
                      <select
                        placeholder="Branch"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option label="Ecobank" value={"MTN"}>
                          Ecobank
                        </option>
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Division
                      </Typography>
                      <select
                        placeholder="Division"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option label="Ecobank" value={"MTN"}>
                          Ecobank
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Bank Detials
              </Typography>
              <hr className="my-1" />
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Bank transfer
                </Typography>
                <div className="w-full flex flex-col my-1 gap-2">
                  <select
                    placeholder="Bank"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option label="Ecobank" value={"MTN"}>
                      Ecobank
                    </option>
                  </select>
                  <Input
                    crossOrigin="anonymous"
                    placeholder="Branch"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  <Input
                    crossOrigin="anonymous"
                    placeholder="Account number"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Mobile Money Details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Wallet Type
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Wallet Number"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end">
                <Button
                  size="sm"
                  className="flex flex-row items-center bg-red-400 font-normal"
                >
                  <BiPlus size={20} />
                  Create client
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">
              List of Users of GLICO General Insurance
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    // value={searchText}
                    // onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {[
                      "name",
                      "Profile",
                      "Phone number",
                      "TIN",
                      "Bank Details",
                      "Momo Details",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[{}, {}, {}, {}, {}, {}, {}]?.map(
                    (item: any, index: number) => {
                      const isLast = index === [{}]?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={item?.name + index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              Hohn Doe
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              +233 5462 3233
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              doe@gmail.com
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              Accra
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              Admin
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              Admin
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "staff",
                                  JSON.stringify({
                                    id: item?.id,
                                    // name: item?.name,
                                    // role: item?.role,
                                    // branch: item?.branch,
                                    // phone: item?.branch,
                                  })
                                );
                                history.push("/dashboard/setup/manage-staff");
                                window.location.reload();
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage Staff
                            </Button>
                            <span className="mx-2"></span>
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>

            {true && (
              <div className="my-4 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={prevPage}
                    // disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{1}</strong> of{" "}
                    <strong className="text-gray-900">
                      {/* {Math.ceil(
                        (data?.admins?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )} */}
                      1
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={nextPage}
                    // disabled={
                    //   activePage ===
                    //   Math.ceil(
                    //     (data?.admins?.filter(filterData).length || 0) /
                    //       PAGE_SIZE
                    //   )
                    // }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default CompanyStaff;
