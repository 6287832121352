import { useHistory, useLocation } from "react-router-dom";
import useLoginModel from "../useLogin";
import { Button, Input, Spinner } from "@material-tailwind/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BASEURL } from "../../../util";

type LoginType = {
  phone: string;
};

export default function LoginOtp() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>();
  const history = useHistory();

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    if (data?.phone?.trim()?.length < 10) {
      return toast.error("Phone number required");
    }
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      };

      const req = await fetch(
        `${BASEURL}/auth/request-reset-password`,
        options
      );

      const res = await req.json();

      if (req.ok) {
        if (res?.status === 200) {
          const { data } = res;
          toast.success(
            res?.message ?? "Password reset request sent successfully"
          );
          reset();
          localStorage.setItem("user_id", data?.user_id);
          setTimeout(() => {
            history.push("/change-password");
            window.location.reload();
          }, 1500);
        } else {
          toast.error(res?.data ?? "An error occured");
        }
      } else {
        toast.error(res?.data ?? "An error occured");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <main className="auth flex items-center justify-center h-screen bg-white">
      <div className="flex  flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          /> */}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Request OTP
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Phone number associated with account
              </label>
              <div className="mt-2">
                <Input
                  crossOrigin={""}
                  id="phone"
                  error={!!errors.phone}
                  type="text"
                  label="Phone number"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <span className="text-left mt-2 text-xs text-brand-primary">
                    Phone is required
                  </span>
                )}
              </div>
            </div>

            <div>
              <Button
                variant="gradient"
                size="lg"
                type="submit"
                style={{
                  background: "#ED7C31",
                }}
                className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
              >
                {isSubmitting ? (
                  <Spinner className="w-5 h-5 text-white" />
                ) : (
                  "Send OTP"
                )}
              </Button>
            </div>
          </form>

          <p className="mt-5 text-center text-sm text-gray-500">
            Take me back to?{" "}
            <a
              href="/"
              className="font-semibold leading-6 text-brand-primary hover:text-brand-primary"
            >
              Login
            </a>
          </p>
        </div>
      </div>
    </main>
  );
}
