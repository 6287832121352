import { Button, Input, Spinner, Typography } from "@material-tailwind/react";
import useLoginModel from "../useLogin";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BASEURL } from "../../../util";
import Logo from "../../../assets/logo.png";
import { UserType } from "../../../types/user";
import { GET_ROLE_PERMISSIONS } from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { DASHBOARD_USERTYPE } from "../../../types/role";
import systemconfig from "../../../systemconfig";




type LoginType = {
  email: string;
  password: string;
};

export default function LoginEmail() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>();

  const { data, loading, refetch } = useQuery(GET_ROLE_PERMISSIONS);

  
  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    const { email } = data;
    try {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      };

      const req = await fetch(`${systemconfig.baseUrl}/auth/login`, options);

      const res = await req.json();

      if (req.ok) {
        if (res?.access_token) {
          const { data } = res;

          let resData = {
            email: email,
            active_status: res?.data?.active_status,
            block: res?.data?.block,
            first_name: res?.data?.first_name,
            last_name: res?.data?.last_name,
            phone_number: res?.data?.phone_number,
            role: res?.data?.role,
            role_id: res?.data?.role_id,
            id: res?.data?.id,
            user_type: res?.data?.user_type,
          };

          // localStorage.setItem("user_type", "company");
          localStorage.setItem("user_type", res?.data?.user_type);
          localStorage.setItem("token", res?.access_token);
          localStorage.setItem("expires_at", res?.expires_at);
          localStorage.setItem("user", JSON.stringify(resData));

          history.push("/dashboard");
          window.location.reload();
        } else {
          toast.error(res?.data);
        }
      } else {
        toast.error(res?.data);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <main className="w-full h-screen bg-black grid grid-cols-1 lg:grid-cols-2">
      <div className="h-full bg-white flex flex-col items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="mb-10">
            <img src={Logo} alt="logo" className="w-36 object-cover" />
          </div>
          <p className="mb-4 text-grey-700 font-bold">Login to your account</p>

          <div className="my-5" />

          <Input
            crossOrigin={""}
            id="email"
            error={!!errors.email}
            type="email"
            label="Email"
            size="lg"
            className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className="text-left mt-2 text-xs text-brand-primary">
              Email is required
            </span>
          )}

          <div className="my-6" />

          <Input
            crossOrigin={""}
            size="lg"
            id="password"
            type="password"
            error={!!errors.password}
            label="Enter a password"
            className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
            {...register("password", { required: true })}
          />
          {errors.password && (
            <span className="text-left mt-2 text-xs text-brand-primary">
              Password is required
            </span>
          )}

          <Button
            variant="gradient"
            size="lg"
            type="submit"
            style={{
              background: "#ED7C31",
            }}
            className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
          >
            {isSubmitting ? (
              <Spinner className="w-5 h-5 text-white" />
            ) : (
              "Sign In"
            )}
          </Button>
          <p className="text-sm leading-relaxed text-grey-900 my-2 text-center">
            Forgot password?{" "}
            <a href="request-otp" className="font-bold text-red-500">
              Reset here
            </a>
          </p>
        </form>
      </div>
      <div className="hidden lg:flex h-full flex-col items-center justify-center text-center">
        <img
          className="w-96"
          src="https://www.datapine.com/images/dashboards-different-devices.png"
        />
        <Typography className="mt-10 w-52 text-sm font-semibold text-white">
          Your Number One Smart Insurance App Provider
        </Typography>
      </div>
    </main>
  );
}
