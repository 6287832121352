import React, { useMemo, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Avatar,
  Button,
  IconButton,
  Input,
  Spinner,
  Tab,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BASEURL } from "../../../util";
import { useHistory } from "react-router-dom";

type PasswordType = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

const Settings = () => {
  const history = useHistory();
  const tabs = ["Personal information", "Change password"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedFile, setSelectedFile] = useState<File | null | any>(null);
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");
  const [user, setUser] = useState<UserType>({
    id: "",
    active_status: false,
    block: false,
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    role_id: "",
    role: {
      name: null,
    },
  });

  const toggleType1 = () =>
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");

  const toggleType2 = () =>
    passwordType2 === "password"
      ? setPasswordType2("text")
      : setPasswordType2("password");

  const toggleType3 = () =>
    passwordType3 === "password"
      ? setPasswordType3("text")
      : setPasswordType3("password");

  const defaultValues = useMemo(() => {
    return {
      phone_number: user?.phone_number,
      first_name: user?.first_name,
      last_name: user?.last_name,
      address: user?.address,
      active_status: false,
      block: user?.block,
      email: user?.email,
      role_id: user?.role_id,
      role: user?.role,
    };
  }, [user]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<UserType>({
    defaultValues: defaultValues,
  });

  const {
    register: passWordRegister,
    handleSubmit: hnadlePasswordSubmit,
    formState: { errors: passwordError, isSubmitting: isChanging },
  } = useForm<PasswordType>();

  const onSubmit: SubmitHandler<UserType> = async (data) => {
    try {
      const response = await fetch(`${BASEURL}/auth/edit-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: user?.email,
          role_id: user?.role_id,
          phone_number: data?.phone_number,
          admin_id: user?.id,
        }),
      });

      const req = await response.json();

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data);
        }
        toast.success(req?.message ?? "Profile has been changed succesfully");
        const updatedData = {
          ...user,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
        };
        localStorage.setItem("user", JSON.stringify(updatedData));
      } else {
        toast.info(req?.data ?? "Something went wrong");
      }
    } catch (error) {
      toast.error("Error updating profile");
    }
  };

  const changePassword: SubmitHandler<PasswordType> = async (data) => {
    if (data?.new_password !== data?.confirm_password) {
      toast.warn("New password and confirm password does not match");
      return;
    }
    try {
      const response = await fetch(`${BASEURL}/auth/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          old_password: data?.old_password,
          new_password: data?.new_password,
        }),
      });

      const req = await response.json();

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data);
        }
        toast.success(req?.message ?? "Password has been changed succesfully");
        localStorage.clear();
        history?.push("/");
        window.location.reload();
      } else {
        toast.info(req?.data ?? "Something went wrong");
      }
    } catch (error) {
      toast.error("Error updating password");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);
  };

  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value);
      });
    }
  }, [defaultValues]);

  React.useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <CustomHeader title="Settings" />

      <div className="grid grid-cols-1  lg:grid-cols-3 gap-5">
        <div className="w-full">
          <Tabs id="custom-animation" value={selectedTab}>
            <TabsHeader className="bg-brand-primary flex flex-col py-2">
              {tabs?.map((tab) => (
                <Tab
                  key={tab}
                  value={tab}
                  onClick={() => setSelectedTab(tab)}
                  className={`${
                    selectedTab === tab && "text-gray-900"
                  } place-items-start w-full py-2 text-sm font-semibold `}
                >
                  {tab}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>

        <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
          {selectedTab === "Personal information" ? (
            <>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full flex flex-col md:flex-col lg:flex-row gap-5"
              >
                <div className="w-full lg:w-1/5 flex items-center justify-center lg:items-start lg:justify-start">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full  cursor-pointer"
                  >
                    <img
                      className="h-20 w-20 rounded-full object-cover object-center border border-brand-primary shadow-xl shadow-brand-primary/20 ring-4 ring-brand-primary"
                      src={
                        selectedFile
                          ? selectedFile
                          : "https://glicogroup.com/wp-content/uploads/2022/01/glicogroup-logo-card.jpeg"
                      }
                      alt="nature image"
                    />
                    <input
                      onChange={handleFileChange}
                      accept="image/*"
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                    />
                  </label>
                </div>
                <div className="w-full">
                  <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        Your Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        placeholder="name"
                        className=" !border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("first_name", { required: true })}
                      />
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        Last name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("last_name", { required: true })}
                      />
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        placeholder="Phone number"
                        className=" !border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone_number", { required: true })}
                      />
                    </div>
                  </div>
                  <Button type="submit" className="mt-6">
                    {isSubmitting ? (
                      <Spinner className="w-5 h-5 text-white" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </>
          ) : selectedTab === "Set pin" ? (
            <>
              <form className="w-full">
                <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <div className="w-full">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-sm font-bold mb-3"
                      type="password"
                    >
                      Old pin
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      type="number"
                      maxLength={6}
                      className=" !border-blue-gray-200 focus:!border-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      minLength={6}
                    />
                  </div>
                  <div className="w-full">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-sm font-bold mb-3"
                      type="password"
                    >
                      New pin
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      type="number"
                      maxLength={6}
                      minLength={6}
                      className=" !border-blue-gray-200 focus:!border-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>

                <Button className="mt-6">Update</Button>
              </form>
            </>
          ) : (
            <>
              <form
                className="w-full"
                onSubmit={hnadlePasswordSubmit(changePassword)}
              >
                <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <div className="w-full">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-sm font-bold mb-3"
                      type="password"
                    >
                      Old password
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      type={passwordType2}
                      error={!!passwordError.old_password}
                      className=" !border-blue-gray-200 focus:!border-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      icon={
                        <button
                          onClick={toggleType2}
                          type="button"
                          className="mr-10 outline-none"
                        >
                          {passwordType2 === "password" ? (
                            <FiEyeOff />
                          ) : (
                            <FiEye />
                          )}
                        </button>
                      }
                      {...passWordRegister("old_password", { required: true })}
                    />
                  </div>
                  <div className="w-full">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-sm font-bold mb-3"
                      type="password"
                    >
                      New password
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      type={passwordType}
                      error={!!passwordError.new_password}
                      className=" !border-blue-gray-200 focus:!border-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      icon={
                        <button
                          onClick={toggleType1}
                          type="button"
                          className="mr-10 outline-none"
                        >
                          {passwordType === "password" ? (
                            <FiEyeOff />
                          ) : (
                            <FiEye />
                          )}
                        </button>
                      }
                      {...passWordRegister("new_password", { required: true })}
                    />
                  </div>
                  <div className="w-full">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-sm font-bold mb-3"
                    >
                      Confirm password
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      error={!!passwordError.confirm_password}
                      type={passwordType3}
                      className=" !border-blue-gray-200 focus:!border-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      icon={
                        <button
                          onClick={toggleType3}
                          type="button"
                          className="mr-10 outline-none"
                        >
                          {passwordType3 === "password" ? (
                            <FiEyeOff />
                          ) : (
                            <FiEye />
                          )}
                        </button>
                      }
                      {...passWordRegister("confirm_password", {
                        required: true,
                      })}
                    />
                  </div>
                </div>

                <Button type="submit" className="mt-6">
                  {isChanging ? (
                    <Spinner className="w-5 h-5 text-white" />
                  ) : (
                    "Update"
                  )}
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Settings;
