import systemconfig from "../systemconfig";
export const BASEURL = systemconfig.baseUrl
console.log(BASEURL)

export function calculateDaysLeft(startDate: string, endDate: string) {
  // Convert the dates to milliseconds
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  const difference = end - start;

  // Convert the difference from milliseconds to days
  const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

/** Validate Ghana card */
export const cardNumberPattern = /^(GHA|NRG)-\d{9}-\d$/;

export const id_types = [
  { code: "01", name: "Driver's License" },
  { code: "02", name: "Voter Id" },
  { code: "03", name: "Passport" },
  { code: "04", name: "National ID" },
  { code: "05", name: "NHIS Card" },
  { code: "06", name: "Other" },
];

export const intermediary_types = [
  { code: "02", name: "Broker" },
  { code: "03", name: "Reinsurance" },
  { code: "04", name: "Direct" },
  { code: "01", name: "Agent" },
];
