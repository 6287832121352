import {
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { BASEURL, calculateDaysLeft } from "../../../util";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { CampaignType } from "../../../types/campaign";
import { UserType } from "../../../types/user";
import dayjs from "dayjs";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { RiAdminLine, RiCustomerService2Line } from "react-icons/ri";
import { MdMoney, MdOutlinePolicy } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa6";
import { TbPencilDiscount } from "react-icons/tb";
import { HiBuildingOffice } from "react-icons/hi2";
import { GiCash } from "react-icons/gi";
import { useQuery } from "@apollo/client";
import {
  DASHBOARDAGGREGATES,
  GETTOTALPOLICYPERMONTH,
} from "../../../GraphQl/queries";
import { DashboardAggregateType } from "../../../types/query";
import { CustomBarChart } from "../../../components";
import { toast } from "react-toastify";
import { CustomSubscriopChart } from "../../../components/charts/barChart";
import systemconfig from "../../../systemconfig";



function AdminstratorDashboardOverview() {
  const storageData = localStorage.getItem("user");
  const [figure, setFigure] = useState<string>();
  const history = useHistory();
  const [subscription_data, setsubscription_data] = useState<
    | {
      month: string;
      subscription: number;
    }[]
    | undefined
  >();
  const [loadingSubscription, setLoadinSubscription] = useState(true);
  const { data, loading, refetch } =
    useQuery<DashboardAggregateType>(DASHBOARDAGGREGATES);

  const { data: policiesPermonth, loading: loadingPolicypermonth } = useQuery<{
    months_policies: {
      month: string;
      total_amount: number;
    }[];
  }>(GETTOTALPOLICYPERMONTH);

  useEffect(() => {
    if (storageData) {
      const parsedUser=JSON.parse(storageData);
      if(parsedUser.role.name !== "super_admin"){
        history.push("/dashboard/client")
      }
      else{
        history.push("/dashboard")

      }
      // setUser(JSON.parse(storageData));
    }
  }, []);

  useEffect(() => {
    let amount =
      parseFloat(data?.subscription_rate[0]?.rate as string) *
      parseFloat(data?.total_policy_sold[0]?.total_amount_sold as string);

    // Check if 'amount' is not null or undefined before updating the state
    if (!isNaN(amount)) {
      setFigure(
        (amount / 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [data]);


  const fetchSubscriptionData = async () => {
    try {
      const response = await fetch(`${systemconfig.baseUrl}/auth/get-subscription_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const req = await response.json();

      if (response.ok) {
        setsubscription_data(req?.data?.subscription_data);
      }
      setLoadinSubscription(false);
    } catch (error: any) {
      setLoadinSubscription(false);
      toast.error(error?.message ?? "Failed to fetch subscription data");
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  return (
    <>
      <CustomHeader title="Overview" />
      <div className="w-full">
        <div className="w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
          <Card className="bg-brand-success rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    (data?.insurance_companies?.aggregate?.count ?? 0) +  (data?.broker?.aggregate?.count ?? 0)
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  clients
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>

          {/*  */}
          <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.policy_holders[0]?.total_policyholders
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Policyholders
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-brand-purple rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.staff?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  staff
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.broker?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Brokers
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.insurance_companies?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Insurer company
                </Typography>
              </div>
              <HiBuildingOffice size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-brand-gray-dark rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.agent?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Agents
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-[#003566] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.policies?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Total Policies
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-[#004e98] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.total_policy_sold[0]?.total_amount_sold !== null ? (
                      "GH₵" +
                      (
                        data?.total_policy_sold[0]?.total_amount_sold as any
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    ) : (
                      "GH₵0.00"
                    )
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Total Policy sold
                </Typography>
              </div>

              <MdMoney size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-[#ef476f] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.subscription_amount.aggregate.sum.amount !== null ? (
                      "GH₵" +
                      (
                        data?.subscription_amount.aggregate.sum.amount as any
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    ) : (
                      "GH₵0.00"
                    )
                  )}
                </Typography>

                <Typography className="text-sm uppercase font-normal text-white">
                  Subscription Paid
                </Typography>
              </div>
              <GiCash size={28} color="#cbcbcb" />
            </div>
          </Card>

          {/* <Card className="bg-[#0f3db1] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loading ? <Spinner className="mb-2 text-white" /> : "300"}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Outstanding subscription
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card> */}
          {/*  */}
        </div>

        <div className="mt-6 w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5"></div>
      </div>

      <div className="grid gap-6 my-7 grid-cols-1 lg:grid-cols-2">
        <div className="rounded-xl bg-white py-3">
          <span className="text-sm px-3 mb-4 font-semibold">
            Policies sold per month (GHS)
          </span>
          <div className="h-96 w-full mt-10 flex items-center justify-center">
            {loadingPolicypermonth ? (
              <Spinner />
            ) : (
              <CustomBarChart data={policiesPermonth?.months_policies} />
            )}
          </div>
        </div>

        <div className="rounded-xl bg-white py-3">
          <span className="text-sm px-3 mb-4 font-semibold">
            Subscription Paid per month(GHS)
          </span>
          <div className="h-96 w-full mt-10 flex items-center justify-center">
            {loadingSubscription ? (
              <Spinner className="flex items-center justify-center" />
            ) : (
              <CustomSubscriopChart data={subscription_data} />
            )}
          </div>
        </div>
      </div>

      <div className="my-28"></div>
    </>
  );
}

export default AdminstratorDashboardOverview;
