import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
  RouteProps,
} from "react-router-dom";
// import { LoginEmail, Signup } from "./pages";
import { DashboardContent } from "./components";
import { ComponentType, useEffect, useState } from "react";
import LoginEmail from "./pages/login/email";
import Signup from "./pages/login/signup";
import { PaymentFailedPage, PaymentSuccessPage } from "./pages";
import ChangePassword from "./pages/login/change-password";
import LoginOtp from "./pages/login/otp";

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardContent} />
        <Route path="/payment-success" component={PaymentSuccessPage} />
        <Route path="/payment-failed" component={PaymentFailedPage} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/request-otp" component={LoginOtp} />
        <Route path="/" component={LoginEmail} />
        <Route path="/signup" component={Signup} />
        {/* <Route path="/overview" component={Overview} /> */}
        {/* <Route path="brands" component={Brands} /> */}
        {/* <Route path="/games" component={GamePage} /> */}
      </Switch>
    </Router>
  );
}

export default App;
