import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
  } from "@material-tailwind/react";
  import { NavLink, Link, useHistory } from "react-router-dom";
  import Logo from "../../assets/logo.png";
  import { useState } from "react";
  import { toast } from "react-toastify";
  import {
    MdAutorenew,
    MdDashboard,
    MdOutlineDiscount,
    MdOutlinePolicy,
    MdPayments,
    MdReport,
    MdSettings,
    MdWorkspacePremium,
  } from "react-icons/md";
  import { MdOutlinePhonelinkSetup } from "react-icons/md";
  import { MdOutlineSecurity } from "react-icons/md";
  import {
    FaClipboardUser,
    FaCoins,
    FaMapLocation,
    FaMoneyBillWave,
    FaUserShield,
    FaUserTie,
    FaUsers,
  } from "react-icons/fa6";
  import { TiPointOfInterestOutline } from "react-icons/ti";
  import { GiCoins } from "react-icons/gi";
  import { BiChevronDown, BiChevronRight } from "react-icons/bi";
  import { IoSettings } from "react-icons/io5";
  import { FaTruckLoading } from "react-icons/fa";
  import { AiOutlineTransaction } from "react-icons/ai";
  import { RiChatNewFill } from "react-icons/ri";
  import { TbDeviceMobileCog } from "react-icons/tb";
  import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
  
  export function CompanySidebarContent() {
    const history = useHistory();
    const [open, setOpen] = useState<string>(
      localStorage.getItem("sidebarOpen") ?? "0"
    );
  
    const handleOpen = (value: string) => {
      setOpen(open === value ? "0" : value);
      localStorage.setItem("sidebarOpen", open === value ? "0" : value);
    };
  
    const handleLogout = async () => {
      try {
        localStorage.clear();
        history?.push("/");
        window.location.reload();
      } catch (error) {
        toast.error("Failed to log out");
      }
    };
  
    return (
      <Card className="sidebar-container h-full overflow-y-scroll bg-black w-full max-w-[20rem] rounded-none  p-4">
        <div className="mb-1 p-4">
          <img src={Logo} alt="logo" className="w- object-cover" />
        </div>
        <List>
          <a href={"/dashboard"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard" && "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
            >
              <ListItemPrefix>
                <MdDashboard />
              </ListItemPrefix>
              Overview
            </ListItem>
          </a>
  
          <a href={"/dashboard/client"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/client" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <FaUserTie />
              </ListItemPrefix>
              Client 360
            </ListItem>
          </a>
          <a href={"/dashboard/policy"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/policy" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <MdOutlinePolicy />
              </ListItemPrefix>
              Policy 360
            </ListItem>
          </a>
          {/* <a href={"/dashboard/payments"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/payments" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <MdPayments />
              </ListItemPrefix>
              All Payments
            </ListItem>
          </a> */}
  
          <List className="text-white bg-black -mt-2">
            <Accordion
              open={open === "6"}
              icon={
                <BiChevronDown
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === "6" ? "rotate-180" : ""
                  }`}
                  color="white"
                />
              }
            >
              <ListItem
                className={`${
                  history?.location?.pathname === "/dashboard/setup" &&
                  "bg-red-400"
                } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                selected={open === "6"}
              >
                <AccordionHeader
                  onClick={() => handleOpen("6")}
                  className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                >
                  <ListItemPrefix>
                    <AiOutlineTransaction color="white" />
                  </ListItemPrefix>
                  <Typography
                    color="white"
                    className="text-sm mr-auto font-normal"
                  >
                    Technical Transactions
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1 text-white capitalize">
                <List className="p-0 z-50 bg-black">
                  <a href={"/dashboard/new-policy"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/new-policy" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <RiChatNewFill />
                      </ListItemPrefix>
                      New Policies
                    </ListItem>
                  </a>
                  <a href={"/dashboard/quotations"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/quotations" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaUsers />
                      </ListItemPrefix>
                      quotations
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/renewals"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/renewals" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <MdAutorenew />
                      </ListItemPrefix>
                      Renewals{" "}
                    </ListItem>
                  </a>
                </List>
              </AccordionBody>
            </Accordion>
          </List>
  
          <List className="text-white bg-black -mt-2">
            <Accordion
              open={open === "3"}
              icon={
                <BiChevronDown
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === "3" ? "rotate-180" : ""
                  }`}
                  color="white"
                />
              }
            >
              <ListItem
                className={`${
                  history?.location?.pathname === "/dashboard/setup" &&
                  "bg-red-400"
                } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                selected={open === "3"}
              >
                <AccordionHeader
                  onClick={() => handleOpen("3")}
                  className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                >
                  <ListItemPrefix>
                    <FaMoneyBillWave color="white" />
                  </ListItemPrefix>
                  <Typography
                    color="white"
                    className="text-sm mr-auto font-normal"
                  >
                    Financial Transaction
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1 text-white capitalize">
                <List className="p-0 z-50 bg-black">
                  <a href={"/dashboard/premium"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/premium" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaMoneyBillWave color="white" />
                      </ListItemPrefix>
                      Premiums
                    </ListItem>
                  </a>
                  <a href={"/dashboard/commissions"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/commissions" && "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaCoins />
                      </ListItemPrefix>
                      Commissions
                    </ListItem>
                  </a>
                  <a href={"/dashboard/requisition"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/requisition" && "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaCoins />
                      </ListItemPrefix>
                      Payment requisition
                    </ListItem>
                  </a>
                  <a href={"/dashboard/orders"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/orders" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaCoins />
                      </ListItemPrefix>
                      Payment orders
                    </ListItem>
                  </a>
                  <a href={"/dashboard/approvals"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/approvals" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
                    >
                      <ListItemPrefix>
                        <FaCoins />
                      </ListItemPrefix>
                      Payment approvals
                    </ListItem>
                  </a>
                </List>
              </AccordionBody>
            </Accordion>
          </List>
  
          <List className="text-white bg-black -mt-2">
            <Accordion
              open={open === "1" || open === "2" || open === "2.2"}
              icon={
                <BiChevronDown
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === "1" || open === "2" || open === "2.2"
                      ? "rotate-180"
                      : ""
                  }`}
                  color="white"
                />
              }
            >
              <ListItem
                className={`${
                  history?.location?.pathname === "/dashboard/setup" &&
                  "bg-red-400"
                } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                selected={open === "1" || open === "2" || open === "2.2"}
              >
                <AccordionHeader
                  onClick={() => handleOpen("1")}
                  className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                >
                  <ListItemPrefix>
                    <MdOutlinePhonelinkSetup color="white" />
                  </ListItemPrefix>
                  <Typography
                    color="white"
                    className="text-sm mr-auto font-normal"
                  >
                    System Setup
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1 text-white capitalize">
                <List className="p-0 z-50 bg-black">
                  {/* <a href={"/dashboard/setup/location"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/location" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaMapLocation strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Region/district/town
                    </ListItem>
                  </a> */}
                  {/* <a href={"/dashboard/setup/client_types"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/client_types" && "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaClipboardUser />
                      </ListItemPrefix>
                      Client types
                    </ListItem>
                  </a> */}
                  <a href={"/dashboard/setup/workgroups"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/workgroups" && "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaClipboardUser />
                      </ListItemPrefix>
                      Workgroup/Divisions
                    </ListItem>
                  </a>
                  <a href={"/dashboard/setup/roles"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/roles" && "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <MdSettings />
                      </ListItemPrefix>
                      Roles & Permissions
                    </ListItem>
                  </a>
  
                  <List className="text-white bg-black -mt-1">
                    <Accordion
                      open={open === "2"}
                      icon={
                        <BiChevronDown
                          strokeWidth={2.5}
                          className={`mx-auto h-4 w-4 transition-transform ${
                            open === "2" ? "rotate-180" : ""
                          }`}
                          color="white"
                        />
                      }
                    >
                      <ListItem
                        className={`${
                          history?.location?.pathname === "/dashboard/setup" &&
                          "bg-red-400"
                        } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                        selected={open === "2"}
                      >
                        <AccordionHeader
                          onClick={() => {
                            handleOpen(open === "1" ? "2" : "1");
                          }}
                          className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                        >
                          <ListItemPrefix>
                            <TbDeviceMobileCog color="white" />
                          </ListItemPrefix>
                          <Typography
                            color="white"
                            className="text-sm mr-auto font-normal"
                          >
                            App Users
                          </Typography>
                        </AccordionHeader>
                      </ListItem>
                      <AccordionBody className="py-1 text-white capitalize">
                        <List className="p-0 z-50 bg-black">
                          <a href={"/dashboard/setup/staff"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/staff" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaUserShield
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Staff
                            </ListItem>
                          </a>
  
                          <a href={"/dashboard/setup/agent"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/agent" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <HiOutlineBuildingOffice2
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Agents
                            </ListItem>
                          </a>
                        </List>
                      </AccordionBody>
                    </Accordion>
                  </List>
  
                  <a href={"/dashboard/setup/commissions"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/commissions" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-2 text-white capitalize -mt-2`}
                    >
                      <ListItemPrefix>
                        <TiPointOfInterestOutline
                          strokeWidth={3}
                          className="h-3 w-5"
                        />
                      </ListItemPrefix>
                      Commissions Setup
                    </ListItem>
                  </a>
  
                  {/* <List className="text-white bg-black -mt-1">
                    <Accordion
                      open={open === "2.2"}
                      icon={
                        <BiChevronDown
                          strokeWidth={2.5}
                          className={`mx-auto h-4 w-4 transition-transform ${
                            open === "2.2" ? "rotate-180" : ""
                          }`}
                          color="white"
                        />
                      }
                    >
                      <ListItem
                        className={`${
                          history?.location?.pathname === "/dashboard/setup" &&
                          "bg-red-400"
                        } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                        selected={open === "2.2"}
                      >
                        <AccordionHeader
                          onClick={() => {
                            handleOpen(open === "1" ? "2.2" : "1");
                          }}
                          className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                        >
                          <ListItemPrefix>
                            <MdOutlinePolicy
                              strokeWidth={3}
                              className="h-3 w-5 text-white"
                            />
                          </ListItemPrefix>
                          <Typography
                            color="white"
                            className="text-sm mr-auto font-normal"
                          >
                            Policy Setup
                          </Typography>
                        </AccordionHeader>
                      </ListItem>
                      <AccordionBody className="py-1 text-white capitalize">
                        <List className="p-0 z-50 bg-black">
                          <a href={"/dashboard/setup/business-class"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/business-class" &&
                                "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePolicy
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Class of business
                            </ListItem>
                          </a>
                          <a href={"/dashboard/setup/peril"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/peril" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePolicy
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Perils
                            </ListItem>
                          </a>
                          <a href={"/dashboard/setup/exclusion"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/exclusion" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePolicy
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Exclusions
                            </ListItem>
                          </a>
                          <a href={"/dashboard/setup/cover-types"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/cover-types" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePolicy
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Type of Cover
                            </ListItem>
                          </a>
                        </List>
                      </AccordionBody>
                    </Accordion>
                  </List> */}
  
                  {/* <a href={"/dashboard/roles"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname === "/dashboard/roles" &&
                        "bg-red-400"
                      } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <MdOutlineSecurity />
                      </ListItemPrefix>
                      Roles and Permissions
                    </ListItem>
                  </a> */}
  
                  {/* <a href={"/dashboard/setup/premium-rates"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/premium-rates" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <MdWorkspacePremium strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Premium Rates Setup
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/setup/discounts"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/discounts" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <MdOutlineDiscount strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Discounts Setup
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/setup/loadings"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/loadings" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaTruckLoading strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Loadings Setup
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/setup/commissions"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/commissions" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <TiPointOfInterestOutline
                          strokeWidth={3}
                          className="h-3 w-5"
                        />
                      </ListItemPrefix>
                      Commissions Setup
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/setup/levies"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/levies" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaCoins strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Levies and Charges
                    </ListItem>
                  </a>
  
                  <a href={"/dashboard/setup/taxes"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/taxes" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <GiCoins strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Taxes Setup
                    </ListItem>
                  </a> */}
                </List>
                {/* <List className="text-white bg-black -mt-3">
            <Accordion
              open={open === "2"}
              icon={
                <BiChevronDown
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === "2" ? "rotate-180" : ""
                  }`}
                  color="white"
                />
              }
            >
              <ListItem
                className={`${
                  history?.location?.pathname === "/dashboard/setup" &&
                  "bg-red-400"
                } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                selected={open === "1"}
              >
                <AccordionHeader
                  onClick={() => handleOpen("2")}
                  className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                >
                  <ListItemPrefix>
                    <TbDeviceMobileCog color="white" />
                  </ListItemPrefix>
                  <Typography
                    color="white"
                    className="text-sm mr-auto font-normal"
                  >
                    App Users
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1 text-white capitalize">
                <List className="p-0 z-50 bg-black">
                  <a href={"/dashboard/setup/intermediaries"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/intermediaries" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaUserShield strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Intermediaries
                    </ListItem>
                  </a>
                  <a href={"/dashboard/setup/staff"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/staff" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <FaClipboardUser strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Staff
                    </ListItem>
                  </a>
                  <a href={"/dashboard/setup/insurance-companies"}>
                    <ListItem
                      className={`${
                        history?.location?.pathname ===
                          "/dashboard/setup/insurance-companies" && "bg-red-400"
                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                    >
                      <ListItemPrefix>
                        <HiOutlineBuildingOffice2
                          strokeWidth={3}
                          className="h-3 w-5"
                        />
                      </ListItemPrefix>
                      insurance Companies
                    </ListItem>
                  </a>
                </List>
              </AccordionBody>
            </Accordion>
          </List> */}
              </AccordionBody>
            </Accordion>
          </List>
  
          <a href={"/dashboard/reports"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/reports" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <MdReport color="white" />
              </ListItemPrefix>
              report
            </ListItem>
          </a>
  
          <a href={"/dashboard/settings"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/settings" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <IoSettings color="white" />
              </ListItemPrefix>
              settings
            </ListItem>
          </a>
  
          {/* <div className="absolute w-full bottom-7 "> */}
          <div className="hover:bg-red-400 rounded-xl items-start justify-start">
            <div className="h-0.5 w-fit bg-white rounded-md" />
            <ListItem
              onClick={handleLogout}
              className="hover:bg-transparent hover:text-white text-white text-xs w-full"
            >
              <ListItemPrefix>
                <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
              </ListItemPrefix>
              Log Out
            </ListItem>
          </div>
          {/* </div> */}
        </List>
      </Card>
    );
  }
  