import React, { useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { FiDelete } from "react-icons/fi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GET_ALL_DISTRICTS } from "../../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import ConfirmationDialog from "../../../../config/confirmationbox";
import { CREATE_DISTRICT } from "../../../../GraphQl/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

interface Params {
  id: string; // Define the type for the 'id' parameter
}

type DataType = {
  id: string;
  name: string;
  category: string;
  capital: string;
  region: {
    name: string;
  };
};

const ManageDistrict = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const PAGE_SIZE = 8;
  const { id } = useParams<Params>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    data: districts,
    loading,
    refetch,
  } = useQuery<{ districts: DataType[] }>(GET_ALL_DISTRICTS, {
    variables: {
      regionId: id,
    },
  });

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    capital: "",
  });

  const [AddDistrict, { error, loading: RequestLoading }] = useMutation(
    CREATE_DISTRICT,
    {
      onCompleted: (data) => {
        toast.success("You have successfully added a district");
        refetch();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Failed to add district"),
    }
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setShowConfirmation(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfirmSubmit = () => {
    if (!formData?.capital || !formData.capital || !formData.name) {
      return toast.error("All fields required");
    }
    AddDistrict({
      variables: {
        name: formData.name,
        region_id: id,
        category: formData.category,
        capital: formData.capital,
      },
    });
    setShowConfirmation(false);
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (item: DataType) => {
    const isTextMatch =
      item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.capital?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.category?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.region?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = districts?.districts
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (districts?.districts?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  return (
    <>
      <CustomHeader title="Districts" />
      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={handleConfirmSubmit}
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <div className="bg-black p-3">
            <Typography className="text-base text-white">Region</Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Region name"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {districts && districts.districts.length > 0 && (
                <tr className="transition-all bg-gray-300">
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {districts?.districts[0]?.region?.name}
                    </Typography>
                  </td>
                  {/* Add other columns or data you want from the first item */}
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-base text-white">
              Create district
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      name="name"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Capital
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      name="capital"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      value={formData.capital}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Category
                    </Typography>
                    <div>
                      <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="border-gray-600 border focus:border-t-gray-900  rounded-md h-12 text-[14px] w-full"
                      >
                        <option value="">Select category</option>
                        <option value="Municipal">Municipal</option>
                        <option value="Distict"> Distict</option>

                        {/* Add more categories as needed */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={(e) => handleSubmit(e)}
                size="sm"
                className="flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
                {RequestLoading ? "Saving..." : "Save district"}
              </Button>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base capitalize">districts</Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto">
                <thead className="">
                  <tr>
                    {["name", "Category", "capital", "", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.category}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.capital}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <button
                            onClick={() => {
                              localStorage.setItem("district", item?.name);
                              history.push(
                                `/dashboard/setup/manage_town/${item?.id}`
                              );
                              window.location.reload();
                            }}
                            className="font-normal text-xs capitalize bg-brand-indigo p-2 rounded-md text-white hover:shadow-md"
                          >
                            Manage towns
                          </button>
                        </td>
                        <td className={classes}>
                          {/* <button className="font-normal text-xs capitalize bg-red-600 p-2 rounded-md text-white hover:shadow-md">
                            Manage district
                          </button> */}
                          <IconButton color="red" size="sm">
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !loading && (
              <div className="mt-10 flex text-white items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-700">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-700">
                      {Math.ceil(
                        (districts?.districts?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (districts?.districts?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManageDistrict;
